body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  color: white;
  font-size: 17px;
  line-height: 2rem;
}
h1 {
  font-size: 2.6rem;
  margin: 40px 0;
  line-height: 2.5rem;
}
h2 {
  font-weight: 300;
  margin: 40px 0;
}
h3 {
  font-weight: 600;
  font-size: 1.3rem;
  margin: 30px 0 10px;
}
p {
  color: #234058;
  font-weight: 500;
  line-height: 1.5rem;
  margin: 10px 0
}
a:link {
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 15px;
  }
  h1 {
    font-size: 2rem;
  }
}
