body {
  background-color: #0cc1e6;
  max-width: 1260px;
  margin: auto;
}
.hero-image {
  max-width: 1000px;
}
section {
  max-width: 650px;
  margin: 90px auto;
  padding: 0 30px;
}
.timeline {
  margin: 50px 0;
  width: 100%;
  display: flex;
}
.timeline .desktop {
  max-width: 220px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  section {
    margin-top: 0px;
  }
}